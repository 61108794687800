body {
  overflow: hidden;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('./assets/background.jpg'); /* Mobile background */
  background-size: cover;
  height: 100vh;
  justify-content: center;
  padding: 20px;
}

/* Media query for devices with a minimum width of 768px (tablets and desktops) */
@media (min-width: 768px) {
  .login-container {
    background-image: url('./assets/background-desktop.jpg'); /* Desktop background */
  }
}

.logo {
  width: 80%;
  height: auto;
  max-width: 300px;
}

.input-container, .button-container {
  width: 100%;
  max-width: 400px; /* Match max-width for both input and button */
  display: flex;
  flex-direction: column;
}

input {
  display: block;
  margin-bottom: 15px; /* Space between inputs and button */
  padding: 15px; /* Same padding for inputs */
  width: 100%; /* Full width of the container */
  border: 1px solid #ccc;
  border-radius: 8px; /* Same border-radius for consistency */
}

.login-button {
  position: absolute;
  margin-bottom: 15px; /* Space below the button */
  padding: 15px; /* Same padding as inputs */
  width: 100%;
  max-width: 248px; /* Full width of the container */
  font-size: 16px; /* Same font size */
  border-radius: 8px; /* Same border-radius */
  cursor: pointer;
  background-color: #ff5a5f;
  color: white;
  border: none;
  text-align: center; /* enter text inside the button */
  
}

.login-button:hover {
  opacity: 0.8;
}

h1 {
  color: white;
}
